import axios from 'axios';



export default class ApiService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.baseUrl}/${url}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post(url, data) {
    let header = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-org-id': '12mqs9kd',
        'x-api-key': 'jwkplk69uepiiwml',
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.baseUrl}/${url}`, data, header)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          console.log('Reject from lexisNexis:', error);
          reject(error);
        });
    });
  }
  put(url, data) {
    let header = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-org-id': '12mqs9kd',
        'x-api-key': 'jwkplk69uepiiwml',
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .put(`${this.baseUrl}/${url}`, data, header)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          console.log('Reject from lexisNexis:', error);
          reject(error);
        });
    });
  }

  patch(url, data) {
    let header = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-org-id': '12mqs9kd',
        'x-api-key': 'jwkplk69uepiiwml',
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .patch(`${this.baseUrl}/${url}`, data, header)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          console.log('Reject from lexisNexis:', error);
          reject(error);
        });
    });
  }




  getSDKToken(applicantId) {
    let data = { applicantId: applicantId };
    return new Promise((resolve, reject) => {
      this.post(process.env.REACT_APP_ONFIDO_SDK, data)
        .then((resp) => resolve(resp))
        .catch((error) => reject(error));
    });
  }
}




const createOktaUser = async (newUser) => {
  try {
    const oktaApiUrl = process.env.REACT_APP_OKTA_ORG_URL; // Replace with your actual Okta API URL
    const oktaApiToken = process.env.REACT_APP_OKTA_TOKEN; // Replace with your actual Okta API token

    const response = await axios.post(oktaApiUrl, newUser, {
      headers: {
        Authorization: `SSWS ${oktaApiToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const createOktaUserService = {
  createOktaUser: function (
    API_BASE_URL,
    API_VERSION,
    authenticationHeader
  ) {
    return axios.post(
      API_BASE_URL + API_VERSION + "/create",
      authenticationHeader
    );
  },
};




export { createOktaUser };
