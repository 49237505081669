import React, { useState, useEffect } from "react";
import { Steps } from "antd";
import { SolutionOutlined, FileDoneOutlined } from "@ant-design/icons";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import TransactionDetails from "./TransactionDetails";
import ApiService from "../../services/ApiService";
import ThreatComplete from "./ThreatComplete";
import DocumentVerification from "./DocumentVerification";

const people = [
  { id: 1, name: "Tom Cook-626217817271" },
  { id: 2, name: "Wade Cooper-8191728199101" },
  { id: 3, name: "Tanya Fox-019163728171" },
];

const ThreatStepper = () => {
  const [current, setCurrent] = useState(0);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [selected, setSelected] = useState(people[1]);


  const email = "user.transactionapp@mailinator.com";
  const apiService = new ApiService(process.env.REACT_APP_BASEURL);

  const balance = 1000;

  const nextStep = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleAmountChange = (e) => {
    const enteredAmount = parseFloat(e.target.value);
    if (isNaN(enteredAmount)) {
      setError("Please enter a valid amount");
    } else if (enteredAmount > balance) {
      setError("Amount exceeds available balance");
    } else {
      setError("");
    }
    setAmount(enteredAmount);
  };




  const items = [
    {
      key: "Transaction Details",
      title: "Transaction Details",
      icon: <AccountBalanceWalletIcon />,
    },
    {
      key: "LexisNexis Document Verification",
      title: "LexisNexis Document Verification",
      icon: <SolutionOutlined />,
    },
    // { key: "Completed", title: "Completed", icon: <FileDoneOutlined /> },
  ];

  return (

    <div className=" bg-neutral-100 h-screen py-6 mx-auto my-auto box-border">
      <div className="multi-step-parent-wrapper max-w-[650px] bg-white mx-auto p-6 shadow-lg rounded-lg max-h-full">

        {current > 0 && (
          <div className="flex items-center mb-4">
            <button
              className="text-neutral-900 hover:bg-neutral-300 hover:text-neutral-900 transition-colors duration-200 mt-2 tex-sm border-blue-950 text-sm font-medium rounded-full p-2"
              onClick={prev}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            </button>
          </div>
        )}

        {/* <Steps
          size="small"
          className="bg-white rounded-top pt-4 pb-2"
          current={current}
          items={items}
        /> */}

        <div className="h-full">
          {current === 0 && (
            <TransactionDetails
              amount={amount}
              setAmount={setAmount}
              handleAmountChange={handleAmountChange}
              balance={balance}
              error={error}
              setError={setError}
              nextStep={nextStep}
              selected={selected}
              setSelected={setSelected}
              people={people}
            />
          )}
          {current === 1 && (
            <DocumentVerification nextStep={nextStep} email={email} amount={amount} />
          )}
          {/* {current === 2 && (
              <ThreatComplete amount={amount} selected={selected} />
            )} */}
        </div>
        <div className="flex justify-between w-full"></div>
      </div>
    </div>
  );
};

export default ThreatStepper;
