import "./App.css";
import { Route, Routes } from "react-router-dom";


import { ToastContainer } from "react-toastify";


import ThreatStepper from "./components/ThreatDetection/ThreatStepper";
import AccountOverview from "./components/ThreatDetection/AccountOverview";

import Custom404 from "./components/notfound/Custom404";
import ThreatComplete from "./components/ThreatDetection/ThreatComplete";


const App = () => {

  return (

    <Routes>
      <Route path="/" exact element={<ThreatStepper />} />

      <Route path="/accountOverview" element={<AccountOverview />} />
      <Route path="/callback/accept" element={<ThreatComplete />} />
      <Route path="*" element={<Custom404 />} />

      {/* <ToastContainer /> */}
    </Routes>

  );
};

export default App;
