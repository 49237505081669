import React, { useState } from "react";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TransactionDetails = ({
  nextStep,
  amount,
  setAmount,
  handleAmountChange,
  balance,
  error,
  setCurrent,
  people,
  selected,
  setSelected,
}) => {
  const [query, setQuery] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!error && amount > 0) {
      console.log("Amount to transfer:", amount);
      setAmount(amount);
      nextStep();
    }
  };
  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
        return person.name.toLowerCase().includes(query.toLowerCase());
      });

  return (
    <div className="flex flex-col gap-4">

      <h1 className="text-center font-semibold text-blue-950 text-2xl pb-2 border-b border-neutral-300">Send Money</h1>
      <p className="flex flex-col text-sm text-slate-500">
        Account No:{" "}
        <span className="text-gray-700 text-lg font-medium">
          766666211612
        </span>
      </p>
      <p className="flex flex-col text-slate-500 text-sm">
        Available Balance:{" "}
        <span className="text-gray-700 text-lg font-medium">
          ${balance}
        </span>
      </p>
      <div>
        <label className="block mb-0 text-slate-500 text-sm">Enter Amount</label>
        <input
          type="number"
          placeholder="Enter amount"
          value={amount}
          onChange={handleAmountChange}
          className="border w-full px-3 py-2 mt-2 focus:outline-none "
        />
      </div>
      {error && <p className="text-red-500">{error}</p>}

      <form onSubmit={handleFormSubmit}>
        <div>
          <label className="block mb-2 text-slate-500 text-sm">Select Receiver's Account</label>

          <Combobox value={selected} onChange={(value) => setSelected(value)}>
            <div className="relative">
              <ComboboxInput
                className={clsx(
                  "w-full rounded-sm bg-white border py-1.5 pr-8 pl-3 text-sm/6 text-slate-500",
                  "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                )}
                displayValue={(person) => person?.name}
                onChange={(event) => setQuery(event.target.value)}
              />
              <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                <ChevronDownIcon className="size-4 fill-slate-900 border-2 text-slate-500 group-data-[hover]:fill-slate-950" />
              </ComboboxButton>
            </div>
            <Transition
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <ComboboxOptions
                anchor="bottom"
                className="w-[var(--input-width)] rounded-md border border-neutral-300 text-neutral-500 bg-white p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
              >
                {filteredPeople.map((person) => (
                  <ComboboxOption
                    key={person.id}
                    value={person}
                    className="group flex items-center gap-2 rounded-md py-1.5 px-3 select-none data-[focus]:bg-white/10 cursor-pointer"
                  >
                    <CheckIcon className="invisible size-4 fill-slate-900 group-data-[selected]:visible" />
                    <div className="text-sm/6 text-slate-900">
                      {person.name}
                    </div>
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            </Transition>
          </Combobox>
        </div>
        <button
          type="submit"
          className={classNames(
            "py-2 md:py-3 px-4 md:px-4 w-full mt-8 transition-colors duration-200 border-2 border-blue-950 bg-blue-950 hover:bg-blue-900 hover:border-blue-900  focus:ring focus:ring-blue-800   rounded focus:outline-none  text-white cursor-pointer"
          )}
          disabled={!!error || amount <= 0}
        >
          Next
        </button>
      </form>
    </div>
  );
};

export default TransactionDetails;
