import React, { useEffect, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CryptoJS from "crypto-js";
import Custom404 from "../notfound/Custom404";
import { dividerClasses } from "@mui/material";

const ThreatComplete = () => {
  const [transactionAmount, setTransactionAmount] = useState("");
  const [receiptName, setReceiptName] = useState("");
  const [isVerified, setIsVerified] = useState(false);


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const hash = params.get("hashvalue");

    // Exclude hashvalue, flowValue, and request_id
    const splitParameters = window.location.search
      .substring(1)
      .split("&")
      .filter((param) => !param.startsWith("hashvalue") && !param.startsWith("flowValue") && !param.startsWith("request_id"))
      .sort();
    const newParameters = splitParameters.join("&");

    const password = process.env.REACT_APP_CLIENT_SECRET;


    let saltedPassword = password + newParameters;

    for (let i = 0; i < 10; i++) {
      saltedPassword = CryptoJS.SHA256(saltedPassword).toString(CryptoJS.enc.Hex);
    }
    const hash2 = saltedPassword;


    if (hash2 === hash) {
      console.log('verified successfully');
      const transactionAmountparam = params.get("transaction");
      const recipientNameparam = params.get("email");
      setTransactionAmount(transactionAmountparam);
      setReceiptName(recipientNameparam);
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }, []);

  // Get the current date in the format "dd Month yyyy"
  const currentDate = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  if (!isVerified) {
    return <Custom404 />;
  }

  return (
    <div className=" bg-neutral-100 h-screen py-6 mx-auto my-auto box-border">
      <div className="multi-step-parent-wrapper max-w-[650px] bg-white mx-auto p-6 shadow-lg rounded-lg max-h-full">

        <div className="flex-col justify-center w-full items-start gap-4 inline-flex">
          <div className="justify-center items-center gap-4 inline-flex">
            <div className="w-6 h-6 relative text-green-500">
              <CheckCircleOutlinedIcon />
            </div>
            <div className="text-neutral-700 text-xl">
              You have successfully completed the transfer.
            </div>
          </div>
          <div className="w-full h-px border border-zinc-200"></div>
          <div className="border border-zinc-200"></div>
          <div className="justify-between items-center inline-flex w-full">
            <div className="text-zinc-500 text-base">From </div>
            <div className="text-neutral-600 text-base text-left">
              A/C No: 766666211612
            </div>
          </div>
          <div className="justify-between items-center inline-flex w-full">
            <div className="text-zinc-500 text-base">To </div>
            <div className="text-neutral-600 text-base">{receiptName}</div>
          </div>
          <div className="justify-between items-center inline-flex w-full">
            <div className="text-zinc-500 text-base">Amount</div>
            <div className="text-neutral-600 text-base">$ {transactionAmount}</div>
          </div>
          <div className="justify-between items-center inline-flex w-full">
            <div className="text-zinc-500 text-base">Date </div>
            <div className="text-neutral-600 text-base">{currentDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreatComplete;
