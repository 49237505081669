import React from 'react';

const DocumentVerification = ({ nextStep, email, amount }) => {
    const handleNext = () => {
        nextStep();
    };

    return (
        <>
            <iframe className='min-h-[500px] my-auto flex items-center w-full' src={`${process.env.REACT_APP_LEXISURL}?email=${email}&verificationClientId=${process.env.REACT_APP_CLIENT_ID}&transaction=${amount}`} title="lexis"></iframe>

        </>
    );
};

export default DocumentVerification;



// 
